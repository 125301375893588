import styled from "styled-components"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { TPlan } from "src/data/user/user"
import { spacing } from "src/ui/spacing"

export function FeatureBlockerText({
  children,
  isFeatureAvailable,
  requiredPlan,
  hidden,
}: {
  children: React.ReactNode
  isFeatureAvailable?: boolean
  requiredPlan?: TPlan
  hidden?: boolean
}) {
  if (isFeatureAvailable || hidden) {
    return <>{children}</>
  }

  return (
    <ContentBox>
      <div>{children}</div>{" "}
      <StyledUpgradeIconButton
        context="featureblocker-generic"
        requiredPlan={requiredPlan}
      />
    </ContentBox>
  )
}

const ContentBox = styled.div`
  display: flex;
  align-items: center;
`

// transform is there to avoid optical illusion where it looks to not be centered
const StyledUpgradeIconButton = styled(UpgradeIconButton)`
  margin-left: ${spacing.XS};
`
